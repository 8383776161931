import { createStore } from 'redux';

export const reducer = function (state = { profile: null, videos: null }, action) {
    console.info('dispatching', action)
    switch (action.type) {
        case "INCREMENT":
            return state + 1;
        case "DECREMENT":
            return state - 1;
        case "ADD_VIDEOS":
            return {
                ...state,
                videos: action.payload
            };
        case "ADD_PROFILE":
            return {
                ...state,
                profile: action.payload
            };
        case "ADD_SESSIONS":
            return {
                ...state,
                sessions: action.payload
            };
        default:
            return state;
    }
  };

export let store = createStore(reducer);