export const maxVideoWidth = "400px";
export const maxVideoWidthNumber = "400";
export const green = "#377361"; // #377361
export const lightGreen = "rgb(105, 195, 143)";
export const darkGrey = "#5E5E5E"
export const lightGrey ="#DFDFDF"
export const orange = "#FFA17A" //"#F29F05"
export const offWhite = "#f8f8ff"
export const purple = "#68648D"
export const gold = "#7D652E"
export const blue = "#416C90"
export const lime = "#D5EC8B"
export const skin = "#FFA17A"
export const pink = "#7AD8FF"
export const almostBlack = "#212121";