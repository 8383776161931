import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faFolder, } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Routes } from "../routes";
import Logo from "../assets/img/ceevie_logo_black.png";

import { darkGrey, lightGrey, lime, offWhite, orange, purple, skin, almostBlack, pink } from "../constants";
import { useScrollPosition } from "../common/hooks/useScrollPosition";

const CookieOverlay = React.lazy(() => import('../components/CookieOverlay'));
const CallToActionSection = React.lazy(() => import('./components/CallToActionSection'));



export default () => {

  const history = useHistory();

  const scrollPosition = useScrollPosition();
  
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <div className="">
      <Navbar 
        variant="dark"
        expand="lg"
        bg="#f8f8ff"
        style={{zIndex: "150"}}
       className={`d-none d-sm-block navbar-transparent navbar-theme-primary sticky-top ${(scrollPosition) > 0 ? 'shadow' : ''} ${(scrollPosition) > 0 ? 'whiteBackground' : 'transparent'}`} // ${(scrollPosition) > 0 ? 'shadow' : ''}
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image style={{ height: "40px" }} src={Logo} />
          </Navbar.Brand>
            <div>
              <Button as={HashLink} to="sign-in" variant="outline-white" className="ms-3" style={styles.loginButton}>Login</Button>
              <Button as={HashLink} to="sign-up" variant="outline-white" className="ms-3" style={styles.interestingButton} className="enlarge">Sign Up</Button>
            </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden d-none d-sm-block" id="home" style={{ backgroundColor: offWhite, marginTop: "-76px"}}>
        <CookieOverlay />
        <Container>
          <Row className="pb-4">
            <Col lg={6} md={12} className="d-flex " style={{ display: "flex", flexDirection: "column", backgroundColor: "transparent", paddingTop: "0px"}}>
                <div className="greenShadow" style={{ height: "900px", width: "900px", position: "relative", top: "-500px", left: "-200px"}} />
                <div className="d-flex" style={{ display: "flex", flexDirection: "column", marginTop: "-900px", zIndex: "100"}}>
                  <h1 className="fontPoppins fontLarge" style={{ fontSize: "70px", lineHeight: "80px", color: "black"}}>Be Seen.</h1>
                  <p className="fontPoppins fontMedium" style={{ color: "black"}}>Video CVs for professionals</p>
                  <p className="fontPoppins fontSmall" style={{ color: "black"}}>Stand out from the crowd and show off your skills and personality with an innovative video CV. Create, share and track all within one platform.</p>
                  <Button className="enlarge" as={Link} to={Routes.Signup.path} variant="white" target="_blank" style={{...styles.interestingButton, color: almostBlack, backgroundColor: skin, border: "none", width: "130px", fontWeight: "bold" }}>Start now!</Button>
                  <div style={{ display: "flex", flexDirection: "row", color: darkGrey }} className="pt-6 ">
                    <div style={{ display: "flex", flexDirection: "column", width: "300px"}} className="d-none d-lg-block">
                      <p style={{ margin: "0px", marginRight: "10px", fontWeight: "bold", color: "black"}}className="fontPoppins fontLarge">37.7%</p>
                      <p className="fontPoppins fontSmall mb-0" style={{ color: "black"}}>more interview invites</p>
                      <p 
                        className="fontPoppins pointer"
                        style={{ fontSize: "10px", fontStyle:"italic", color: "black"}}
                        onClick={()=> window.open("https://www.iza.org/publications/dp/13656/video-resumes-and-job-search-outcomes-evidence-from-a-field-experiment", "_blank")}
                      >
                        Link to scientific study
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "320px"}} className="d-none d-lg-block">
                      <p style={{ margin: "0px", marginRight: "10px", fontWeight: "bold", color: "black"}}className="fontPoppins fontLarge">89%</p>
                      <p className="fontPoppins fontSmall mb-0" style={{ color: "black"}}>of employers would watch Video CV</p>
                      <p 
                        className="fontPoppins pointer"
                        style={{ fontSize: "10px", fontStyle:"italic", color: "black"}}
                        onClick={()=> window.open("https://www.liveabout.com/video-resumes-1918979", "_blank")}
                      >
                        Link to survey
                      </p>
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "column",  width: "300px"}}>
                      <p style={{ margin: "0px"}}className="fontPoppins fontMedium">89%</p>
                      <p className="fontPoppins fontSmall">recruiters would watch video CVs</p>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12} className="d-flex align-items-center justify-content-center" style={{ marginTop: "-50px"}}>
              <div style={{ display: "block", marginTop: "150px", marginLeft: "50px" }}>
                <div style={{ overflow: "hidden", height: "390px", width: "180px", borderRadius: "20px", transform: "translateZ(0)"}} className="iosOverflow">
                  <video     
                    height="390px" 
                    style={{ zIndex: "-100", width: "400px", marginLeft: "-110px"}}
                    autoPlay
                    muted
                    loop
                    src={require("../assets/videos/offline/female_caucasian_2.mov")}                 
                    // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fmale_asian_1.mov?alt=media&token=2d029b58-79dd-44c4-b6cd-209a461757cc"} type="video/mov"
                  />
                </div>
                <img 
                  src={require("../assets/img/iphone14.png")} alt="storage"
                  style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px", transform: "translateZ(100)"}}
                  className="img top"       
                />
                <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "180px", borderRadius: "0px 0px 20px 20px" }}>
                  <img 
                      style={{
                        height: "10px", paddingRight: "5px"
                      }}
                      src={require("../assets/img/profile_icon.png")} alt="upload icon"
                  />
                  <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Vanessa Koll</p>
                  <img 
                        style={{  height: "7px", paddingLeft: "75px", marginTop: "2px" }}
                        src={require("../assets/img/list_icon.png")} alt="upload icon"
                    />
                </div>
              </div> 
              <div style={{ display: "block", marginLeft: "50px", marginTop: "-50px" }}>
                      
                      <div style={{ overflow: "hidden", height: "390px", width: "180px", borderRadius: "20px", transform: "translateZ(0)"}}>
                        <video     
                          height="390px" 
                          style={{ zIndex: "-100"}}
                          autoPlay
                          muted
                          loop
                          src={require("../assets/videos/offline/male_africanamerican_2.mov")}
                        
                          // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Ffemale_caucasian_3.mov?alt=media&token=c29483a7-f0cd-430b-9f4c-416870ba34fc"} type="video/mov"
                        />
                      </div>
                      <img 
                        src={require("../assets/img/iphone14.png")} alt="storage"
                        style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px"}}
                        className="img top"
                        
                      />
                      <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "180px", borderRadius: "0px 0px 20px 20px" }}>
                        <img 
                            style={{
                              height: "10px", paddingRight: "5px"
                            }}
                            src={require("../assets/img/profile_icon.png")} alt="upload icon"
                        />
                        <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Mike Smith</p>
                        <img 
                              style={{  height: "7px", paddingLeft: "90px", marginTop: "2px" }}
                              src={require("../assets/img/list_icon.png")} alt="upload icon"
                          />

                      </div>
                    

                    </div>
              </Col>
          </Row>
          <Row className="pt-6">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge pb-4 pt-2" style={{ textAlign: "center", zIndex: "100"}}>What is ceevie?</h1>
            </Col>
          </Row>
          <Row className="pt-4" style={{ marginBottom: "20px",}}>
          <Col xs={12} className="d-flex align-items-center justify-content-center pt-4 d-block d-sm-none">
              <div style={{ display: "block" }}>
                <div style={{ overflow: "hidden", height: "390px", width: "180px", borderRadius: "20px", transform: "translateZ(0)"}} className="iosOverflow">
                  <video     
                    height="390px" 
                    style={{ zIndex: "-100", transform: "rotate(270deg)", width: "400px", marginLeft: "-110px"}}
                    autoPlay
                    muted
                    loop
                    src={require("../assets/videos/offline/female_africanamerican_1.mov")}                 
                    // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fmale_asian_1.mov?alt=media&token=2d029b58-79dd-44c4-b6cd-209a461757cc"} type="video/mov"
                  />
                </div>
                <img 
                  src={require("../assets/img/iphone14.png")} alt="storage"
                  style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px", transform: "translateZ(100)"}}
                  className="img top"       
                />
                <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "180px", borderRadius: "0px 0px 20px 20px" }}>
                  <img 
                      style={{
                        height: "10px", paddingRight: "5px"
                      }}
                      src={require("../assets/img/profile_icon.png")} alt="upload icon"
                  />
                  <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Vanessa Koll</p>
                  <img 
                        style={{  height: "7px", paddingLeft: "75px", marginTop: "2px" }}
                        src={require("../assets/img/list_icon.png")} alt="upload icon"
                    />
                </div>
              </div>           
            </Col> 
            <Col lg={4}>
              <div style={{ display: "flex", flexDirection: "column",zIndex: "100"}} className="pt-2">
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>1. Video clips</h2>
                <p className="fontPoppins fontSmall" style={{ color: "black"}}>The heart of ceevie are the portrait style videos. Upload multiple ones and allow your prospective employer to get to know you interactively clip by clip.</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", zIndex: "100"}} className="pt-5">
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>2. Profile</h2>
                <p className="fontPoppins fontSmall" style={{ color: "black"}}>You can add your name, title, a bio text and location and optionally your timezone. Finally, you can link various other profiles and contact information.</p>
              </div>
            </Col>
            <Col lg={8} className="d-flex align-items-start justify-content-end d-none d-sm-block" >
                <div  style={{ margin: 0, padding: 0,borderRadius: "10px", overflow: "hidden", zIndex: "100", transform: "translateZ(0)", width: "700px"}}>
                  <img 
                      src={require("../assets/img/browserBar.png")} alt="browser window"
                      className="img centeredShadow d-none d-md-block"
                      style={{ width: "700px"}}
                    /> 
                    <img 
                      src={require("../assets/img/browserBar.png")} alt="browser window"
                      className="img centeredShadow d-none d-sm-block d-md-none"
                      style={{ width: "500px"}}
                    /> 
                  <video     
                    autoPlay
                    muted
                    loop
                    style={{ margin: 0, padding: 0, width: "700px" }}
                    className="centeredShadow d-none d-md-block"
                    src={require("../assets/videos/landing_demo.mp4")} type="video/mp4"
                  />
                 
                </div>       
              </Col>
          </Row>
        </Container>
      </section>
      <section className="overflow-hidden pb-2 d-none d-lg-block" id="home" style={{ backgroundColor: offWhite }}>
        <Container>
          <Row>
          <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "center", color: "black"}}>Why use ceevie?</h1>
            </Col>
          </Row>
          <Row className="pt-6" style={{ position: "relative"}}>
            <div className="greenShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "-200px", right: "-200px",}} className="d-none sm-block"/> 
               
            <Col lg={6} className="d-flex align-items-center justify-content-center order-lg-0 order-md-1 mb-lg-1 d-none d-sm-block" style={{ zIndex: "100"}}>
                <div style={{ height: "400px", width: "400px", display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "block", marginTop: "-30px", paddingRight: "30px",  transform: "rotate(354deg)" }}>
                      <div style={{ overflow: "hidden", height: "390px", width: "170px", borderRadius: "20px", transform: "translateZ(0)"}} className="iosOverflow">
                        <video     
                          height="390px" 
                          style={{ zIndex: "-100", transform: "rotate(270deg)", width: "400px", marginLeft: "-110px"}}
                          autoPlay
                          muted
                          loop
                          src={require("../assets/videos/offline/female_africanamerican_1.mov")}
                        
                          // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fmale_asian_1.mov?alt=media&token=2d029b58-79dd-44c4-b6cd-209a461757cc"} type="video/mov"
                        />
                      </div>
                      <img 
                        src={require("../assets/img/iphone14.png")} alt="storage"
                        style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px", transform: "translateZ(100)"}}
                        className="img top"
                        
                      />
                      <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "170px", borderRadius: "0px 0px 20px 20px" }}>
                        <img 
                            style={{
                              height: "10px", paddingRight: "5px"
                            }}
                            src={require("../assets/img/profile_icon.png")} alt="upload icon"
                        />
                        <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Vanessa Koll</p>
                        <img 
                              style={{  height: "7px", paddingLeft: "70px", marginTop: "2px" }}
                              src={require("../assets/img/list_icon.png")} alt="upload icon"
                          />

                      </div>
                    </div>
                    <div style={{ display: "block", transform: "rotate(6deg)" }}>
                      
                      <div style={{ overflow: "hidden", height: "390px", width: "170px", borderRadius: "20px", transform: "translateZ(0)"}}>
                        <video     
                          height="390px" 
                          style={{ zIndex: "-100"}}
                          autoPlay
                          muted
                          loop
                          src={require("../assets/videos/offline/female_caucasian_3.mov")}
                        
                          // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Ffemale_caucasian_3.mov?alt=media&token=c29483a7-f0cd-430b-9f4c-416870ba34fc"} type="video/mov"
                        />
                      </div>
                      <img 
                        src={require("../assets/img/iphone14.png")} alt="storage"
                        style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px"}}
                        className="img top"
                        
                      />
                      <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "170px", borderRadius: "0px 0px 20px 20px" }}>
                        <img 
                            style={{
                              height: "10px", paddingRight: "5px"
                            }}
                            src={require("../assets/img/profile_icon.png")} alt="upload icon"
                        />
                        <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Kim Smith</p>
                        <img 
                              style={{  height: "7px", paddingLeft: "70px", marginTop: "2px" }}
                              src={require("../assets/img/list_icon.png")} alt="upload icon"
                          />

                      </div>
                    

                    </div>
                   
                </div>
              </Col>
              
              <Col lg={6} className="order-lg-1 order-md-0 mb-lg-0" style={{ zIndex: "100"}}>
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>Present more of yourself</h2>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Convince them you are the right candidate</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>A video CV allows you to showcase more of your personality, language and communication skills than a traditional written CV. </p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Grab their attention</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>89% of employers say they would watch a Video CV, but only 25% of written CVs are read by humans.</p>
                  </div>
                </div>
              </Col>
          </Row>
          
          <Row className="pt-6">
              <div className="skinShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "400px", left: "-200px",}} className="d-none sm-block"/> 
                        
              <h2 className="fontPoppins fontMedium" style={{ color: "black", zIndex: "100"}}>Instant Feedback</h2>             
              <Col lg={6} md={12} className="lg-2 mb-5 mb-lg-0" style={{ zIndex: "100"}}>
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Tracking allows you to see the views</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Get instant feedback by seeing the number of views. Every consented session and interaction with your profile is tracked.</p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Super Power: Analytics</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Ceevie let's you analyse the data, showing you valuable insights about the retention rate of your videos.</p>
                  </div>
                </div>

              </Col>
              <Col lg={6} md={12} className="d-flex align-items-center justify-content-center" >
                <div style={{ width: "550px" }}>
                    <div  className="centeredShadow" style={{ margin: 0, marginTop: "-50px", padding: 0, display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden", zIndex: "100"}}>
                      <img 
                          src={require("../assets/img/browserBar.png")} alt="downward arrow"
                          style={{ width: "550px"}}
                          className="img"
                        />
                        <img 
                          src={require("../assets/img/analytics_dashboard.webp")} alt="downward arrow"
                          style={{ width: "550px"}}
                          className="img"
                        />
                    </div>    
                  </div>
              </Col>
          </Row>
          {/* <Row className="pt-6 pb-6">
            <div style={{ backgroundColor: green, width: "100%", margin: "3rem", padding: "5rem", display: "flex", flexDirection: "row", zIndex: "100", borderRadius: "50px"}}>
              <div style={{ display: "flex", flexDirection: "column"}}>
                <p className="fontMedium fontPoppins" style={{ color: "white"}}>Ready to get started?</p>
                <p className="fontMedium fontPoppins" style={{ color: "white"}}>Create an account today!</p>

              </div>
              <div>
                </div>>

            </div>

          </Row>                  */}

          {/* <Row className="pt-8">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "center"}}>Ceevie's features</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: lime, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/storage.png")} alt="storage"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                      
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Storage</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>Store and share up to 1GB of videos in your own personal library on our website.</p>
                </div>

              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: purple, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/hosting.png")} alt="hosting"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Hosting</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>We take care of hosting, so you can focus on creating and sharing your content.</p>
                </div>

              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: skin, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/design.png")} alt="design"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Design</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>Showcase your videos with our beautiful, modern and intuitive website design.</p>
                </div>

              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: blue, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/adfree.png")} alt="adfree"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Ad free</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>Your videos are showcased in a professional manner. No ads, branding or video suggestions.</p>
                </div>

              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: gold, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/analytics.png")} alt="analytics"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Analytics</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>Our tracking features allows you to see how people have interacted with your video CV.</p>
                </div>

              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="pt-5 zoom">
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                <div style={{ flex: 1 }} className="pt-4">
                  <div style={{ height: "50px", width: "50px", backgroundColor: green, borderRadius: "10px"}} className="imgContainer">
                    <img 
                      src={require("../assets/img/responsive.png")} alt="responsive"
                      style={{ height: "30px", margin: "10px"}}
                      className="img"
                      
                    />
                  </div>
                </div>
                <div style={{ flex: 5, padding: "20px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px"}}>Mobile Friendly</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px"}}>Ceevie is mobile-friendly and responsive, making it easy to view your video CV on any device.</p>
                </div>

              </div>
            </Col>
           
          </Row> */}
          </Container>
      </section>
      <section className="overflow-hidden pb-2 d-none d-md-block d-lg-none" id="home" style={{ backgroundColor: offWhite }}>
        <Container>
          <Row>
          <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "center", color: "black"}}>Why use ceevie?</h1>
            </Col>
          </Row>
          <Row className="pt-6" style={{ position: "relative"}}>
            <div className="greenShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "-200px", right: "-200px",}} className="d-none sm-block"/> 
             
              <Col lg={6} className="order-lg-1 order-md-0 mb-lg-0" style={{ zIndex: "100"}}>
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>Present more of yourself</h2>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Convince them you are the right candidate</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>A video CV allows you to showcase more of your personality, language and communication skills than a traditional written CV. </p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Grab their attention</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>89% of employers say they would watch a Video CV, but only 25% of written CVs are read by humans.</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="d-flex align-items-center justify-content-center pt-6" style={{ zIndex: "100"}}>
                <div style={{ height: "400px", width: "400px", display: "flex", flexDirection: "row" }}>
                    <div style={{ display: "block", marginTop: "-30px", paddingRight: "30px",  transform: "rotate(354deg)" }}>
                      <div style={{ overflow: "hidden", height: "390px", width: "170px", borderRadius: "20px", transform: "translateZ(0)"}} className="iosOverflow">
                        <video     
                          height="390px" 
                          style={{ zIndex: "-100", transform: "rotate(270deg)", width: "400px", marginLeft: "-110px"}}
                          autoPlay
                          muted
                          loop
                          src={require("../assets/videos/offline/female_africanamerican_1.mov")}
                          // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fmale_asian_1.mov?alt=media&token=2d029b58-79dd-44c4-b6cd-209a461757cc"} type="video/mov"
                        />
                      </div>
                      <img 
                        src={require("../assets/img/iphone14.png")} alt="storage"
                        style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px", transform: "translateZ(100)"}}
                        className="img top"
                        
                      />
                      <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "170px", borderRadius: "0px 0px 20px 20px" }}>
                        <img 
                            style={{
                              height: "10px", paddingRight: "5px"
                            }}
                            src={require("../assets/img/profile_icon.png")} alt="upload icon"
                        />
                        <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Vanessa Koll</p>
                        <img 
                              style={{  height: "7px", paddingLeft: "70px", marginTop: "2px" }}
                              src={require("../assets/img/list_icon.png")} alt="upload icon"
                          />
                      </div>
                    </div>
                    <div style={{ display: "block", transform: "rotate(6deg)" }}>
                      <div style={{ overflow: "hidden", height: "390px", width: "170px", borderRadius: "20px", transform: "translateZ(0)"}}>
                        <video     
                          height="390px" 
                          style={{ zIndex: "-100"}}
                          autoPlay
                          muted
                          loop
                          src={require("../assets/videos/offline/female_caucasian_3.mov")}
                          // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Ffemale_caucasian_3.mov?alt=media&token=c29483a7-f0cd-430b-9f4c-416870ba34fc"} type="video/mov"
                        />
                      </div>
                      <img 
                        src={require("../assets/img/iphone14.png")} alt="storage"
                        style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px"}}
                        className="img top"
                      />
                      <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "170px", borderRadius: "0px 0px 20px 20px" }}>
                        <img 
                            style={{
                              height: "10px", paddingRight: "5px"
                            }}
                            src={require("../assets/img/profile_icon.png")} alt="upload icon"
                        />
                        <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Kim Smith</p>
                        <img 
                              style={{  height: "7px", paddingLeft: "70px", marginTop: "2px" }}
                              src={require("../assets/img/list_icon.png")} alt="upload icon"
                          />
                      </div>
                    </div>
                </div>
              </Col>
          </Row>
          
          <Row className="pt-6">
              <div className="skinShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "400px", left: "-200px",}} className="d-none sm-block"/> 
                        
              <h2 className="fontPoppins fontMedium" style={{ color: "black", zIndex: "100"}}>Instant Feedback</h2>             
              <Col lg={6} md={12} className="lg-2 mb-5 mb-lg-0" style={{ zIndex: "100"}}>
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Tracking allows you to see the views</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Get instant feedback by seeing the number of views. Every consented session and interaction with your profile is tracked.</p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Super Power: Analytics</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Ceevie let's you analyse the data, showing you valuable insights about the retention rate of your videos.</p>
                  </div>
                </div>

              </Col>
              <Col lg={6} md={12} className="d-flex align-items-center justify-content-center pt-4" >
                <div style={{ width: "550px" }}>
                    <div  className="centeredShadow" style={{ margin: 0, marginTop: "-50px", padding: 0, display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden", zIndex: "100"}}>
                      <img 
                          src={require("../assets/img/browserBar.png")} alt="downward arrow"
                          style={{ width: "550px"}}
                          className="img"
                        />
                        <img 
                          src={require("../assets/img/analytics_dashboard.webp")} alt="downward arrow"
                          style={{ width: "550px"}}
                          className="img"
                        />
                    </div>    
                  </div>
              </Col>
          </Row>
        </Container>
      </section>
      <section className="overflow-hidden pt-md-6 d-none d-sm-block" id="home" style={{ backgroundColor: offWhite }}>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "center", color: "black"}}>Written CV vs Video CV?</h1>
              <p className="fontSmall fontPoppins pt-4" style={{ width: "100%", maxWidth: "750px", textAlign: "center"}}>Written CVs are normally required when applying for a job. Video CVs are not meant to replace, but rather <span style={{ fontWeight: "bold"}}>enhance</span> your application as it allows you to show more skills.</p>

            </Col>
            </Row>
           
            <Row className="d-flex align-items-center justify-content-center pt-4"> 
              
            
              <Col lg={10} className="d-flex align-items-start justify-content-start" style={{ display: "flex", flexDirection: "column", color: "black"}}>
                
                <div style={{ width: "100%", borderRadius: "20px"}}>
                  <div style={{ display: "flex", flexDirection: "row"}}>
                    <p style={{ flex: 2}} className="fontPoppins fontSmall">

                    </p>

                    <p style={{ flex: 1, fontWeight: "bold"}} className="fontPoppins fontSmall">
                      Written CV
                    </p>
                    <p style={{ flex: 1, fontWeight: "bold"}} className="fontPoppins fontSmall">
                      Video CV
                    </p>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Personal information
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)",  marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Educational background 
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px", opacity: "50%"}}
                        className="img"
                      />
                    </div>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Professional background
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px", opacity: "50%"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Personality
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Communication skills
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Language skills
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 2, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Motivation
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  
                </div>

              
                

            </Col>
          
          </Row>
          {/* <Row className="d-flex align-items-start justify-content-start mt-6">
              <Col lg={6}>
                <div style={{ paddingRight: "100px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Video CVs are not a replacement</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Most companies have an established application processes and handing in a well written resume is one important part of this. </p>
                </div>
              </Col>
              <Col lg={6}>
               <div style={{ paddingRight: "100px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>They are an additional tool</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Often it is impossible to interview all qualified candidates, so not all applicants might be given a chance to truly show their skills.</p>
                </div>
              </Col>
            </Row> */}
        </Container>
      </section>
      <section className="pt-6 d-none d-sm-block">
        <Container className="pt-4 pb-4">
          <h1 className="fontPoppins fontLarge" style={{ textAlign: "center", color: "black"}}>How does it work?</h1>
          <Row className="pt-6 pb-2">
          <Col lg={3} className="pt-4 pb-4 d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", fontSize: "84px", paddingBottom: "20px"}}>1.</p>

            <div style={{ display: "block" }}>       
              
              <img 
                src={require("../assets/img/iPhone_record.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
                
              />
             
            </div>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", fontWeight: "bold", marginBottom: "0px", color: "black", marginLeft: "10px"}}>Record videos</p>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", paddingTop: "0px", marginTop: "0px", color: "black", marginLeft: "10px"}}>Use your smart phone to record videos.</p>

          </Col>
          <Col lg={3} className="pt-4 pb-4 d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
          <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", fontSize: "84px", paddingBottom: "20px"}}>2.</p>

            <div style={{ display: "block" }}>       

              
              <img 
                src={require("../assets/img/iPhone_upload.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
                
              />
             
            </div>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", fontWeight: "bold", marginBottom: "0px", color: "black"}}>Upload videos</p>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", paddingTop: "0px", marginTop: "0px", color: "black"}}>Upload up to 10 videos each 1 Min. long and with a title.</p>

          </Col>  
          <Col lg={3} className="pt-4 pb-4 d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
          <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", fontSize: "84px", paddingBottom: "20px"}}>3.</p>

            <div style={{ display: "block" }}>       
              <img 
                src={require("../assets/img/iPhone_profile.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
              />       
            </div>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", fontWeight: "bold", marginBottom: "0px", color: "black"}}>Write your profile</p>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", paddingTop: "0px", marginTop: "0px", color: "black"}}>Add personal information to complete your ceevie.</p>

          </Col>  
          <Col lg={3} className="pt-4 pb-4 d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", fontSize: "84px", paddingBottom: "20px"}}>4.</p>

            <div style={{ display: "block" }}>       
              <img 
                src={require("../assets/img/iPhone_email.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
              />
            </div>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center", fontWeight: "bold", marginBottom: "0px", color: "black"}}>Share your unique url</p>
            <p className="fontSmall fontPoppins" style={{ textAlign: "center",paddingTop: "0px", marginTop: "0px", color: "black"}}>Complement your written CV and cover letter with your ceevie</p>

          </Col>  

          </Row>

         
        </Container>
      </section>
      <section style={{ width: "100vw", backgroundColor: skin }} className="pb-6 d-none d-sm-block">
          <Container>


          <Row className="pt-6 pb-2">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "center", color: "black"}}>Don't just take our word for it...</h1>
              <p className="fontPoppins" style={{ color: "black", width: "500px", textAlign: "center"}}>See what others are saying about Video CVs</p>
            </Col>
         
          </Row>

          <Row className="pt-2">
            
            <Col lg={3} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/bbc.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.bbc.com/worklife/article/20220121-the-jobseekers-boosting-their-applications-with-video-cvs", "_blank")}
              />
            </Col>
            <Col lg={3} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/linkedin.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
              
              />
            </Col>
            <Col lg={3} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/guardian.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.theguardian.com/careers/careers-blog/how-to-make-video-cv", "_blank")}
              
              />
            </Col>
            <Col lg={3} md={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/prospects.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/create-a-great-video-cv", "_blank")}
              
              />
            </Col>
           
          </Row>
         
          </Container>
        
      </section>   
      <section className="d-none d-sm-block">
        <div>
          <Row className="justify-content-between align-items-center pt-6">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ zIndex: "100"}}>
              <h1 className="fontPoppins fontLarge" style={{ width: "500px", textAlign: "center"}}>How can <span style={{ fontWeight: "bold" }}>ceevie</span> help you on your job hunt?</h1>
            </Col>
            {/* <div className="skinShadow" style={{ height: "900px", width: "900px", position: "absolute", right: "-200px"}} /> 
                  */}
          </Row>
          <Row className="p-6" style={{ zIndex: "100"}}>
            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center p-4 float" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
               
                <div style={{ display: "flex", flexDirection: "column", margin: "40px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you applying to a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Competitive Job</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Read how ceevie can help you with difficult applications for jobs in a competitive industry.</p>
                  <Button as={HashLink} to="competitive-jobs" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                  
                </div>
                
              </div>
            </Col>

            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center p-4 float" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
               
                <div style={{ display: "flex", flexDirection: "column", margin: "40px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you trying to get a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Remote Job</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Learn how ceevie can help you gain freedom by landing one of those coveted remote jobs.</p>
                  <Button as={HashLink} to="remote-job" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                </div>
                
              </div>
            </Col>

            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center p-4 float" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
               
                <div style={{ display: "flex", flexDirection: "column", margin: "40px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Young Professional</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Discover how ceevie can help kick start your career and set you up for success.</p>
                  <Button as={HashLink} to="young-professionals" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                </div>
                
              </div>
            </Col>

           
          </Row>
        </div>
      </section>
      <section className="d-none d-sm-block">
        <Container>
          <Row className="justify-content-between align-items-center pt-6 pb-4">
            <Col lg={12} className="d-flex align-items-center justify-content-center" >
              <h1 className="fontPoppins fontLarge">Pricing</h1>
            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center pt-4 pb-8">
            
            
            <Col lg={12} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
              <div className="greenShadow d-none d-sm-block" style={{ height: "900px", width: "900px", position: "absolute", left: "-200px",}} /> 
              <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "30px", zIndex: "100", padding: "20px"}}>

                <div style={{ display: "flex", flexDirection: "row"}} className="p-4">
                  
               

                  <div style={{ flexDirection: "column"}}>
                  <p className="fontPoppins" style={{ fontWeight: "bold", color: darkGrey, margin: "0px", color: "black", fontSize: "94px", lineHeight: "94px", width: "100%", textAlign: "left"}}>$13 <span className="fontSmall fontPoppins"> / month</span></p>

                    <h1 className="fontPoppins fontMedium" style={{ color: lightGrey, margin: "0px", width: "100%", textAlign: "left", paddingTop: "10px" }}>3 Month Package</h1>              

                    <p style={{ color: "black", paddingTop: "10px" }} className="fontPoppins fontSmall">$39 billed once after sign up. No subscription</p>

    
                    <div className="ml-4">
                      <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>up to 10 videos</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>90 days</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>tracking and analytics tool</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>1GB of storage included</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>absolutely no ads or branding</p>
                        </div>
                      
                    </div>
                    <Button as={Link} to={Routes.Signup.path} variant="white" target="_blank" className="enlarge" style={{...styles.interestingButton, color: almostBlack, backgroundColor: skin, border: "none", width: "130px", fontWeight: "bold", marginTop: "20px" }}>Start now!</Button>

                    
                    
                  </div>
                
              </div>
              </div>
              
            </Col>
            
           
            
           
            

            
          </Row>
        </Container>
      </section>
      <CallToActionSection className="d-none d-sm-block"/>
      <section className="d-none d-sm-block">
        <Container>
          <Row>
          <Col lg={12} md={12} className="d-flex align-itmes-center justify-content-center pt-6" style={{  display: "flex", flexDirection: "row", borderRadius: "30px", zIndex: "100" }}>
              <div>
                <img 
                  src={require("../assets/img/testimonial.jpeg")} alt="testlimonial"
                  style={{ height: "220px", width: "140px", objectFit: "cover", borderRadius: "100px"}}
                  className="img"
                />
              </div>
              <div className="p-4 pt-0" style={{ width: "350px"}}>
                <div style={{  display: "flex", flexDirection: "row" }}>
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  
                </div>
                <p className="fontSmallMedium fontPoppins pt-2 mb-2" style={{ color: "black"}}>"Ceevie helped me get interviews for my dream job. I got very positive feedback from a lot of people. <span style={{ fontWeight: "bold"}}>I highly recommend it!"</span></p>
                <p className="fontPoppins" style={{ fontStyle: "italic", fontSize: "12px", color: "black"}}> Alanna M. - Event Manager</p>               
              </div>
              

            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-between align-items-center pt-6">
            <Col lg={12} className="d-flex align-items-center justify-content-center" >
              <h1 className="fontPoppins fontLarge" style={{ color: "black"}}>Frequently Asked Questions</h1>
            </Col>
          </Row>
          <Row className="pt-4">
            <div class="card-wrapper  | content-cc">
              <div class="faq-card" style={{ padding: 0, margin: 0}}>
                <main class="faq-content" style={{ padding: 0, margin: 0}}>
                  <div class="faq-articles" style={{ padding: 0, margin: 0}}>
                    <article class="faq-accordion" style={{ padding: 0, margin: 0}}>
                      <input type="checkbox" class="tgg-title" id="tgg-title-1"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-1">
                          <p className="fontPoppins fontSmall">What is a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)" }}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">A video CV is a short video that showcases a job candidate's skills, qualifications, and experience. It provides employers with a unique and engaging way to learn about the candidate.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-2"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-2">
                          <p className="fontPoppins fontSmall">Why should I use a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">A video CV can help you stand out from the competition and showcase your personality, creativity, and communication skills. It also allows you to provide more information about yourself and your experience than a traditional paper CV.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-3"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-3">
                          <p className="fontPoppins fontSmall">How do I create a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">You can create a video CV using a smartphone. Simply record several short videos, highlighting your skills and experience, and explaining why you're a good fit for the job.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-4"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-4">
                          <p className="fontPoppins fontSmall">Is it free to upload my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">No, we offer one comprehensive paid plan that we believe is an amazing value for job seekers. With ceevie you essentially have your own personal website with a video CV but you do not have to worry about development, hosting or maintenance. We decided to make the plan three months long, as this is the most appropriate for most job seekers. </p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-5"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-5">
                          <p className="fontPoppins fontSmall">Can I update my video CV once it's uploaded?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Yes, you can update your video CV as many times as you need. Simply login to your account, edit the videos or your profile, and save the changes.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-6"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-6">
                          <p className="fontPoppins fontSmall">What should I include in my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Included in your subscription is a guide for how to shoot your videos and what to include, with example prompts for different application types and roles.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-7"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-7">
                          <p className="fontPoppins fontSmall">Can I include additional materials with my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Yes, you can add a short introduction to your profile, and also specify important information like your location, time zone and contact information.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-8"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-8">
                          <p className="fontPoppins fontSmall">How long will my video CV be hosted on your website?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">The video CV will be hosted for three months on our website. Before your subscription ends, you can renew it and your video CV will continue to be hosted by us. You can click "auto renew" in the settings of your account, if you already know that you will use the service for longer. We decide to keep it turned off by default, as for most users a three month subscirption is enough and we want to avoid unintended renewals.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-9"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-9">
                          <p className="fontPoppins fontSmall">Can I delete my video CV from your website at any time?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Of course! You can remove all information and videos at all time.</p>
                      </div>
                    </article> 
                  </div> 
                </main>
              </div>
            </div> 
          </Row>
        </Container>
      </section>
      <section style={{ backgroundColor: almostBlack, width: "100%"}} className="p-4 d-none d-sm-block">
        <Container>
          <Row>
            <Col lg={6} md={12}>
              <p 
                className="fontPoppins p-0 m-0"
                style={{ fontSize: "12px", color: darkGrey, width: "100%", textAlign: "center" }}
                onClick={() => history.push('/')}
              >Copyright © Ceevie 2023 | by Studio Endorfinas S.L.</p>
            </Col>
            <Col lg={6} md={12} style={{ display: "flex", flexDirection: "row"}}>
              <p 
                className="fontPoppins p-0 m-0 pointer" 
                style={{ fontSize: "12px", color: darkGrey, flex: 1, textAlign: "center" }}>
                  <span 
                    onClick={() => history.push('/privacy')}
                  >
                    Privacy Agreement 
                  </span>
                  <span> | </span>
                  <span>
                     Terms and Conditions
                  </span></p>
              </Col>
          </Row>
        </Container>
      </section>
      <section className="d-block d-sm-none" style={{ maxWidth: "100vw", overflowX: "hidden", margin: 0, padding: 0}}>
        <div style={{ width: "100vw", margin: 0, padding: 0, overflow: "hidden", position: "absolute", height: "100vh"}}>
            <div className="greenShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "-300px", left: "-300px"}} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", height: "100px", width: "100vw"}}>
          <Image style={{ height: "40px", marginLeft: "20px", marginTop: "20px" }} src={Logo} />
          <div style={{ position: "absolute", top: "20px", right: "20px"}}>
            <Button as={HashLink} to="sign-in" variant="outline-white" className="ms-3" style={styles.loginButton}>Login</Button>
            <Button as={HashLink} to="sign-up" variant="outline-white" className="ms-3" style={styles.interestingButton} className="enlarge">Sign Up</Button>
          </div>
        </div>
      </section>
      <section className="d-block d-sm-none">
        <Container>
          <Row>
            <div className="d-flex" style={{ display: "flex", flexDirection: "column", zIndex: "100"}}>
              <h1 className="fontPoppins fontLarge" style={{ fontSize: "70px", lineHeight: "80px", color: "black"}}>Be Seen.</h1>
              <p className="fontPoppins fontMedium" style={{ color: "black"}}>Video CVs for professionals</p>
              <p className="fontPoppins fontSmall" style={{ color: "black"}}>Stand out from the crowd and show off your skills and personality with an innovative video CV. Create, share and track all within one platform.</p>
              <Button className="enlarge" as={Link} to={Routes.Signup.path} variant="white" target="_blank" style={{...styles.interestingButton, color: almostBlack, backgroundColor: skin, border: "none", width: "130px", fontWeight: "bold" }}>Start now!</Button>
              
           
            </div>
          </Row>
          <Row className="pt-6" style={{ marginBottom: "20px",}}>
          <Col xs={12} className="d-flex align-items-center justify-content-center d-block d-sm-none">
              <div style={{ display: "block" }}>
                <div style={{ overflow: "hidden", height: "390px", width: "180px", borderRadius: "20px", transform: "translateZ(0)"}} className="iosOverflow">
                  <video     
                    height="390px" 
                    style={{ zIndex: "-100", transform: "rotate(270deg)", width: "400px", marginLeft: "-110px"}}
                    autoPlay
                    muted
                    loop
                    playsInline
                    src={require("../assets/videos/offline/female_africanamerican_1.mov")}
                  
                    // src={"https://firebasestorage.googleapis.com/v0/b/myceevie.appspot.com/o/landing%2Fmale_asian_1.mov?alt=media&token=2d029b58-79dd-44c4-b6cd-209a461757cc"} type="video/mov"
                  />
                </div>
                <img 
                  src={require("../assets/img/iphone14.png")} alt="storage"
                  style={{ height: "400px", margin: "10px", zIndex: "100", marginTop: "-410px", marginLeft: "-8px", transform: "translateZ(100)"}}
                  className="img top"
                  
                />
                <div style={{ height: "40px", position: "relative", top: "-65px", paddingTop: "12px", paddingLeft: "15px", display: "flex", flexDirection: "row", backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.6)", width: "180px", borderRadius: "0px 0px 20px 20px" }}>
                  <img 
                      style={{
                        height: "10px", paddingRight: "5px"
                      }}
                      src={require("../assets/img/profile_icon.png")} alt="upload icon"
                  />
                  <p style={{ fontSize: "8px", color: "white"}} className="fontPoppins">Vanessa Koll</p>
                  <img 
                        style={{  height: "7px", paddingLeft: "75px", marginTop: "2px" }}
                        src={require("../assets/img/list_icon.png")} alt="upload icon"
                    />

                </div>
              </div>
              
            </Col> 
            </Row>
            <Row>
              <Col xs={6}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1, }} className="d-flex align-items-start justify-content-start">
                  <p style={{ margin: "0px", fontWeight: "bold", color: "black"}}className="fontPoppins fontLarge">37%</p>
                  <p className="fontPoppins fontSmall mb-0" style={{ color: "black", textAlign: "left"}}>more interview invites with a video CV</p>
                  <p 
                    className="fontPoppins pointer"
                    style={{ fontSize: "10px", fontStyle:"italic", color: "black"}}
                    onClick={()=> window.open("https://www.iza.org/publications/dp/13656/video-resumes-and-job-search-outcomes-evidence-from-a-field-experiment", "_blank")}
                  >
                    Link to scientific study
                  </p>
                </div>
              </Col>
              <Col xs={6}>
                <div style={{ display: "flex", flexDirection: "column", flex: 1 }} className="d-flex align-items-start justify-content-start">
                  <p style={{ margin: "0px", fontWeight: "bold", color: "black"}}className="fontPoppins fontLarge">89%</p>
                  <p className="fontPoppins fontSmall mb-0" style={{ color: "black", textAlign: "left"}} >of employers would watch a video CV</p>
                  <p 
                    className="fontPoppins pointer"
                    style={{ fontSize: "10px", fontStyle:"italic", color: "black"}}
                    onClick={()=> window.open("https://www.liveabout.com/video-resumes-1918979", "_blank")}
                  >
                    Link to survey
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="pt-6">
            <Col lg={4}>
              <div style={{ display: "flex", flexDirection: "column",zIndex: "100"}} className="pt-2">
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>1. Video clips</h2>
                <p className="fontPoppins fontSmall" style={{ color: "black"}}>The heart of ceevie are the portrait style videos. Upload multiple ones and allow your prospective employer to get to know you interactively clip by clip.</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", zIndex: "100"}} className="pt-5">
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>2. Profile</h2>
                <p className="fontPoppins fontSmall" style={{ color: "black"}}>You can add your name, title, a bio text and location and optionally your timezone. Finally, you can link various other profiles and contact information.</p>
              </div>
            </Col>
            <Col lg={8} className="d-flex align-items-start justify-content-end d-none d-sm-block" >
                <div className="centeredShadow" style={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden", zIndex: "100", width: "100%", transform: "translateZ(0)"}}>
                  <img 
                      src={require("../assets/img/browserBar.png")} alt="browser window"
                      className="img"
                      style={{ width: "900px"}}
                    /> 
                    <img 
                src={require("../assets/img/demo.png")} alt="downward arrow"
                style={{ width: "700px"}}
                className="img"
              />
                 
                </div>       
              </Col>
          </Row>
        </Container>
      </section>
      <section className="d-block d-sm-none">
        <p style={{ width: "100%", textAlign: "center", opacity: "50%", padding: 0, margin: 0}} className="fontPoppins fontSmall">scroll right ></p>
      </section>
      <section style={{ width: "100vw", overflowX: "scroll"}} className="scrollbar d-block d-sm-none">
        <div style={{ width: "200%", paddingLeft: "32px", paddingRight: "32px"}}>
          <div className="centeredShadow" style={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden", zIndex: "100", width: "100%", transform: "translateZ(0)"}}>
            <img 
                src={require("../assets/img/browserBar.png")} alt="browser window"
                className="img"
              /> 
               <img 
                src={require("../assets/img/demo.png")} alt="downward arrow"
                style={{ width: "700px"}}
                className="img"
              />
           
          </div>       

        </div>

      </section>
      <section className="d-block d-sm-none">
        <p style={{ width: "100%", textAlign: "center", opacity: "50%"}} className="fontPoppins fontSmall">scroll right ></p>
      </section>
      <section className="overflow-hidden pt-4 d-block d-sm-none" id="home" style={{ backgroundColor: offWhite, zIndex: "100" }}>
        <Container>
          <Row>
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "left", color: "black"}}>Why use a video CV?</h1>
            </Col>
          </Row>
          <Row className="pt-4" style={{ position: "relative"}}>
            <div className="greenShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "-200px", right: "-200px",}} className="d-none sm-block"/> 
              <Col lg={6} className="order-lg-1 order-md-0 mb-lg-0" style={{ zIndex: "100"}}>
                <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>Present more of yourself</h2>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Convince them</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>A video CV allows you to showcase more of your personality, language and communication skills than a traditional written CV. </p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                 
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Grab their attention</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>89% of employers say they would watch a Video CV, but only 25% of written CVs are read by humans.</p>
                  </div>
                </div>
              </Col>
          </Row>
        </Container>
      </section>
      <section className="d-block d-sm-none">
        <p style={{ width: "100%", textAlign: "center", opacity: "50%", padding: 0, margin: 0}} className="fontPoppins fontSmall">scroll right ></p>
      </section>
      <section style={{ width: "100vw", overflowX: "scroll"}} className="scrollbar d-block d-sm-none">
        <div style={{ width: "700px", paddingLeft: "32px", paddingRight: "32px"}}>
          <div  className="centeredShadow" style={{ margin: 0, padding: 0, display: "flex", flexDirection: "column", borderRadius: "10px", overflow: "hidden", zIndex: "100"}}>
            <img 
                src={require("../assets/img/browserBar.png")} alt="downward arrow"
                style={{ width: "700px"}}
                className="img"
              />
              <img 
                src={require("../assets/img/analytics_dashboard.webp")} alt="downward arrow"
                style={{ width: "700px"}}
                className="img"
              />
          </div>  
        </div>
      </section>
      <section className="d-block d-sm-none">
        <p style={{ width: "100%", textAlign: "center", opacity: "50%"}} className="fontPoppins fontSmall">scroll right ></p>
      </section>
      <section className="d-block d-sm-none">
        <Container>
          <Row className="pt-4">
              <div className="skinShadow" style={{ height: "900px", width: "900px", position: "absolute", top: "400px", left: "-200px",}} className="d-none sm-block"/> 
              <h2 className="fontPoppins fontMedium" style={{ color: "black", zIndex: "100"}}>Instant Feedback</h2>             
              <Col lg={6} md={12} className="lg-2 mb-5 mb-lg-0" style={{ zIndex: "100"}}>
              <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Tracking allows you to see the views</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Get instant feedback by seeing the number of views. Every consented session and interaction with your profile is tracked.</p>
                  </div>
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row"}}>
                  <div style={{ flex: 5, paddingTop: "20px"}}>
                    <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Super Power: Analytics</p>
                    <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Ceevie let's you analyse the data, showing you valuable insights about the retention rate of your videos.</p>
                  </div>
                </div>
              </Col>
          </Row>
        </Container>
      </section>
      <section className="overflow-hidden pt-4 d-block d-sm-none" id="home" style={{ backgroundColor: offWhite }}>
        <Container>
          <Row className="d-flex align-items-center justify-content-center">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "left", color: "black"}}>Written CV vs Video CV?</h1>
              <p className="fontSmall fontPoppins pt-4" style={{ width: "100%", maxWidth: "750px", textAlign: "left"}}>Video CVs are not meant to replace, but rather <span style={{ fontWeight: "bold"}}>enhance</span> your application as it allows you to show more skills.</p>

            </Col>
            </Row>
           
            <Row className="d-flex align-items-center justify-content-center pt-4"> 
              
            
              <Col lg={10} className="d-flex align-items-start justify-content-start" style={{ display: "flex", flexDirection: "column", color: "black"}}>
                
                <div style={{ width: "100%", borderRadius: "20px"}}>
                  <div style={{ display: "flex", flexDirection: "row"}}>
                    <p style={{ flex: 1}} className="fontPoppins fontSmall">

                    </p>

                    <p style={{ flex: 1, fontWeight: "bold"}} className="fontPoppins fontSmall">
                      Written CV
                    </p>
                    <p style={{ flex: 1, fontWeight: "bold"}} className="fontPoppins fontSmall">
                      Video CV
                    </p>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Personal information
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)",  marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Educational background 
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px", opacity: "50%"}}
                        className="img"
                      />
                    </div>

                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Professional background
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px", opacity: "50%"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Personality
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Soft skills
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Language skills
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", paddingTop: "20px", borderBottom: "1px lightgrey solid"}}>
                    <p style={{ flex: 1, paddingLeft: "20px" }} className="fontPoppins fontSmall">
                      Motivation
                    </p>

                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      
                    </div>
                    <div style={{ flex: 1}} className="align-items-center justify-content-center">
                      <img 
                        src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                        style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)", marginLeft: "30px"}}
                        className="img"
                      />
                    </div>
                  </div>
                  
                </div>

              
                

            </Col>
          
          </Row>
          {/* <Row className="d-flex align-items-start justify-content-start mt-6">
              <Col lg={6}>
                <div style={{ paddingRight: "100px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>Video CVs are not a replacement</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Most companies have an established application processes and handing in a well written resume is one important part of this. </p>
                </div>
              </Col>
              <Col lg={6}>
               <div style={{ paddingRight: "100px"}}>
                  <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black"}}>They are an additional tool</p>
                  <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black"}}>Often it is impossible to interview all qualified candidates, so not all applicants might be given a chance to truly show their skills.</p>
                </div>
              </Col>
            </Row> */}
        </Container>
      </section>
      <section className="pt-6 d-block d-sm-none">
        <Container>
          <h1 className="fontPoppins fontLarge" style={{ textAlign: "left", color: "black"}}>How does it work?</h1>
          <Row className="pt-2 pb-6">
          <Col lg={3}>
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", width: "100%"}}>First step: record videos</p>
            <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black", width: "100%"}}>Use your smart phone to record videos.</p>
            <div style={{ display: "block" }} className="d-flex align-items-center justify-content-center">       
              <img 
                src={require("../assets/img/iPhone_record.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
                
              />
            </div>
          </Col>
          <Col lg={3} className="pt-4">
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black",  width: "100%"}}>Second step: upload videos</p>
            <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black",  width: "100%"}}>Upload up to 10 videos each 1 Min. long and with a title.</p>
            <div style={{ display: "block" }} className="pt-2 d-flex align-items-center justify-content-center">      
              <img 
                src={require("../assets/img/iPhone_upload.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
                
              />
            </div>
          </Col>  
          <Col lg={3} className="pt-4">
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", width: "100%"}}>Next: write your profile</p>
            <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black", width: "100%"}}>Add personal information to complete your ceevie.</p>


            <div style={{ display: "block" }} className="pt-2 d-flex align-items-center justify-content-center">       
              <img 
                src={require("../assets/img/iPhone_profile.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
              />       
            </div>
           
          </Col>  
          <Col lg={3} className="pt-4">
            <p className="fontSmall fontPoppins" style={{ fontWeight: "bold", marginBottom: "0px", color: "black", width: "100%"}}>Finally: share your unique url</p>
            <p className="fontSmall fontPoppins" style={{ paddingTop: "0px", marginTop: "0px", color: "black", width: "1005"}}>Complement your written CV and cover letter with your ceevie</p>
            <div style={{ display: "block" }} className="pt-2 d-flex align-items-center justify-content-center">       
              <img 
                src={require("../assets/img/iPhone_email.webp")} alt="storage"
                style={{ height: "400px", margin: "10px", zIndex: "100",}}
                className="img top"
              />
            </div>
           
          </Col>  

          </Row>

         
        </Container>
      </section>
      <section style={{ width: "100vw", backgroundColor: skin }} className="d-block d-sm-none pt-5 pb-5">
          <Container>
          <Row className="pt-4 pt-4">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "left", color: "black"}}>Don't just take our word for it</h1>
              <p className="fontPoppins" style={{ color: "black", textAlign: "left"}}>See what others are saying about Video CVs</p>
            </Col>
         
          </Row>

          <Row className="pt-2">
            
            <Col xs={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/bbc.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.bbc.com/worklife/article/20220121-the-jobseekers-boosting-their-applications-with-video-cvs", "_blank")}
              />
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-center" >
              <img 
                src={require("../assets/img/linkedin.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
              
              />
            </Col>
            <Col xs={6}className="d-flex align-items-center justify-content-center pt-4" >
              <img 
                src={require("../assets/img/guardian.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.theguardian.com/careers/careers-blog/how-to-make-video-cv", "_blank")}
              
              />
            </Col>
            <Col xs={6} className="d-flex align-items-center justify-content-center pt-4" >
              <img 
                src={require("../assets/img/prospects.png")} alt="downward arrow"
                style={{ width: "150px", opacity: "50%", filter: "grayscale(100%)"}}
                className="img pointer"
                onClick={()=> window.open("https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/create-a-great-video-cv", "_blank")}
              
              />
            </Col>
          </Row>
          </Container>
      </section>  
      <section className="d-block d-sm-none">
        <Container>
        <div>
          <Row className="justify-content-between align-items-center pt-6">
            <Col lg={12} className="d-flex align-items-center justify-content-center" style={{ zIndex: "100"}}>
              <h1 className="fontPoppins fontLarge" style={{ textAlign: "left"}}>How <span style={{ fontWeight: "bold" }}>ceevie</span> can help you</h1>
            </Col>
            {/* <div className="skinShadow" style={{ height: "900px", width: "900px", position: "absolute", right: "-200px"}} /> 
                  */}
          </Row>
          <Row style={{ zIndex: "100"}}>
            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
                <div style={{ display: "flex", flexDirection: "column", margin: "20px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you applying to a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Competitive Job</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Read how ceevie can help you with difficult applications for jobs in a competitive industry.</p>
                  <Button as={HashLink} to="competitive-jobs" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                  
                </div>
                
              </div>
            </Col>

            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
                <div style={{ display: "flex", flexDirection: "column", margin: "20px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you trying to get a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Remote Job</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Learn how ceevie can help you gain freedom by landing one of those coveted remote jobs.</p>
                  <Button as={HashLink} to="remote-job" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                </div>
                
              </div>
            </Col>
            <Col lg={4} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
              <div style={{ width: "100%", backgroundColor: "white", borderRadius: "30px"}}>
                <div style={{ display: "flex", flexDirection: "column", margin: "20px"}}>
                  <p className="fontPoppins fontSmall pb-0 mb-1" style={{ fontWeight: "bold", color: skin}}>Are you a...</p>
                  <h2 className="fontPoppins fontMedium pt-0 pb-2" style={{ color: "black"}}>Young Professional</h2>
                  <p className="fontPoppins fontSmall pb-4" style={{ color: "black"}}>Discover how ceevie can help kick start your career and set you up for success.</p>
                  <Button as={HashLink} to="young-professionals" style={{ backgroundColor: almostBlack, width: "150px", textAlign: "center", padding: "15px", borderRadius: "100px", border: "none"}}>
                    <p style={{ color: "white", display: "initial"}}>Learn more!</p>
                  </Button>
                </div>
                
              </div>
            </Col>

           
          </Row>
        </div>
        </Container>
      </section> 
      <section className="d-block d-sm-none">
        <Container>
          <Row className="justify-content-between align-items-center pt-6">
            <Col lg={12} xs={12} className="d-flex align-items-center justify-content-center" style={{ display: "flex", flexDirection: "column"}}>
              <h1 className="fontPoppins fontLarge" style={{ color: "black", width: "100%", textAlign: "left"}}>Our pricing</h1>
              {/* <p className="fontPoppins pt-4" style={{ color: "black", textAlign: "left", width: "100%"}}>Cheaper than networking sites and alternatives for video CV websites</p> */}

            </Col>
          </Row>
          <Row className="d-flex align-items-center justify-content-center pt-2 pb-5">
            <Col lg={12} md={12} className="d-flex align-items-center justify-content-center pt-4" style={{ display: "flex", flexDirection: "column"}}>
              <div className="greenShadow d-none d-sm-block" style={{ height: "900px", width: "900px", position: "absolute", left: "-200px",}} /> 
              <div style={{ display: "flex", flexDirection: "column", backgroundColor: "white", borderRadius: "30px", zIndex: "100", padding: "20px"}}>
                <div style={{ display: "flex", flexDirection: "row"}} className="p-4">
                  <div style={{ flexDirection: "column"}}>
                  <p className="fontPoppins" style={{ fontWeight: "bold", color: darkGrey, margin: "0px", color: "black", fontSize: "60px", lineHeight: "60px", width: "100%", textAlign: "left"}}>$13 <span className="fontSmall fontPoppins">/ month</span></p>
                    <h1 className="fontPoppins fontSmall" style={{  margin: "0px", width: "100%", textAlign: "left", paddingTop: "10px" }}>3 Month Package</h1>              
                    <p style={{ color: "lightgrey", paddingTop: "10px" }} className="fontPoppins fontSmall">$39 billed once after sign up. No subscription</p>
                    <div className="ml-4">
                      <div style={{ display: "flex", flexDirection: "row"}}>
                        <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>up to 10 videos</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>90 days</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>tracking and analytics tool</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>1GB of storage included</p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                          <img 
                            src={require("../assets/img/watched_button_large_green.png")} alt="storage"
                            style={{ height: "20px", marginTop: "3px", filter: "grayscale(100%)"}}
                            className="img"
                          />
                          <p className="fontPoppins fontSmall" style={{ paddingLeft: "10px"}}>absolutely no ads or branding</p>
                        </div>
                    </div>
                    <Button as={Link} to={Routes.Signup.path} variant="white" target="_blank" className="enlarge" style={{...styles.interestingButton, color: almostBlack, backgroundColor: skin, border: "none", width: "130px", fontWeight: "bold", marginTop: "20px" }}>Start now!</Button>
                  </div>
              </div>
              </div>
            </Col>
            
           
            
           
            

            
          </Row>
        </Container>
      </section>
      <section className="d-block d-sm-none">
        <Container>
         
          <Row style={{ marginBottom: "50px"}}>
            <Col lg={12} md={12} className="d-flex align-itmes-center justify-content-center" style={{  display: "flex", flexDirection: "row", borderRadius: "30px", zIndex: "100" }}>
              <div className="d-flex align-itmes-center justify-content-center">
                <img 
                  src={require("../assets/img/testimonial.jpeg")} alt="testlimonial"
                  style={{ height: "140px", width: "140px", objectFit: "cover", borderRadius: "100px", alignSelf: "center", justifySelf: "center"}}
                  className="img"
                />
              </div>
              <div className="px-4 pt-0" style={{ width: "350px"}}>
                <div style={{  display: "flex", flexDirection: "row" }}>
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  <img 
                    src={require("../assets/img/star.png")} alt="testlimonial"
                    style={{ height: "20px", paddingRight: "5px"}}
                    className="img"
                  />
                  
                </div>
                <p className="fontSmall fontPoppins pt-2 mb-2" style={{ color: "black"}}>"Ceevie helped me get interviews for my dream job. I got very positive feedback from a lot of people. <span style={{ fontWeight: "bold"}}>I highly recommend it!"</span></p>
                <p className="fontPoppins" style={{ fontStyle: "italic", fontSize: "12px", color: "black"}}> Alanna M. - Event Manager</p>               
              </div>
            </Col>
          </Row>
         
        </Container>
      </section>
      <section style={{ backgroundColor: skin }} className="d-block d-sm-none pt-5 pb-5">
        <Container>
          <Row className="">
            <Col lg={6} md={12} className="d-flex " style={{ display: "flex", flexDirection: "column", backgroundColor: "transparent" }} className="d-flex align-items-center justify-content-center">
                <div style={{ display: "flex", flexDirection: "column"}} >
                  <h2 className="fontPoppins fontMedium" style={{ color: "black"}}>What are you waiting for? <span style={{ fontWeight: "bold"}}>Invite yourself </span>to the interview!</h2>
                  {/* <p className="fontPoppins fontMedium">Video CVs for professionals</p>
                  <p className="fontPoppins fontSmall">Stand out from the crowd and show off your skills and personality with an innovative video CV. Create, share and track all within one platform.</p>*/}
                  <Button className="enlarge" as={Link} to={Routes.Signup.path} variant="white" target="_blank" style={{...styles.interestingButton, color: "white", backgroundColor: "black", border: "none", width: "170px", fontWeight: "bold" }}>Create account</Button>
                  
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="d-block d-sm-none">
      <Container>
          <Row className="justify-content-between align-items-center pt-6">
            <Col lg={12} className="d-flex align-items-center justify-content-center" >
              <h1 className="fontPoppins fontLarge" style={{ color: "black"}}>Frequently asked questions</h1>
            </Col>
          </Row>
          <Row className="pt-4">
            <div class="card-wrapper  | content-cc">
              <div class="faq-card" style={{ padding: 0, margin: 0}}>
                <main class="faq-content" style={{ padding: 0, margin: 0}}>
                  <div class="faq-articles" style={{ padding: 0, margin: 0}}>
                    <article class="faq-accordion" style={{ padding: 0, margin: 0}}>
                      <input type="checkbox" class="tgg-title" id="tgg-title-10"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-10">
                          <p className="fontPoppins fontSmall">What is a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)" }}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">A video CV is a short video that showcases a job candidate's skills, qualifications, and experience. It provides employers with a unique and engaging way to learn about the candidate.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-11"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-11">
                          <p className="fontPoppins fontSmall">Why should I use a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">A video CV can help you stand out from the competition and showcase your personality, creativity, and communication skills. It also allows you to provide more information about yourself and your experience than a traditional paper CV.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-12"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-12">
                          <p className="fontPoppins fontSmall">How do I create a video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">You can create a video CV using a smartphone. Simply record several short videos, highlighting your skills and experience, and explaining why you're a good fit for the job.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-13"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-13">
                          <p className="fontPoppins fontSmall">Is it free to upload my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">No, we offer one comprehensive paid plan that we believe is an amazing value for job seekers. With ceevie you essentially have your own personal website with a video CV but you do not have to worry about development, hosting or maintenance. We decided to make the plan three months long, as this is the most appropriate for most job seekers. </p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-14"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-14">
                          <p className="fontPoppins fontSmall">Can I update my video CV once it's uploaded?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Yes, you can update your video CV as many times as you need. Simply login to your account, edit the videos or your profile, and save the changes.</p>
                      </div>
                    </article> 
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-15"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-15">
                          <p className="fontPoppins fontSmall">What should I include in my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Included in your subscription is a guide for how to shoot your videos and what to include, with example prompts for different application types and roles.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-16"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-16">
                          <p className="fontPoppins fontSmall">Can I include additional materials with my video CV?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Yes, you can add a short introduction to your profile, and also specify important information like your location, time zone and contact information.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-17"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-17">
                          <p className="fontPoppins fontSmall">How long will my video CV be hosted on your website?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">The video CV will be hosted for three months on our website. Before your subscription ends, you can renew it and your video CV will continue to be hosted by us. You can click "auto renew" in the settings of your account, if you already know that you will use the service for longer. We decide to keep it turned off by default, as for most users a three month subscirption is enough and we want to avoid unintended renewals.</p>
                      </div>
                    </article>
                    <article class="faq-accordion">
                      <input type="checkbox" class="tgg-title" id="tgg-title-18"/>
                      <div class="faq-accordion-title">
                        <label for="tgg-title-18">
                          <p className="fontPoppins fontSmall">Can I delete my video CV from your website at any time?</p>
                          <span class="arrow-icon">
                            <img 
                              src={require("../assets/img/arrow_down_green.png")} alt="downward arrow"
                              style={{ height: "7px", filter: "grayscale(100%)"}}
                              className="img"
                            />
                          </span>
                        </label>
                      </div>
                      <div class="faq-accordion-content">
                        <p className="fontPoppins fontSmall">Of course! You can remove all information and videos at all time.</p>
                      </div>
                    </article> 
                  </div> 
                </main>
              </div>
            </div> 
          </Row>
        </Container>
      
      </section>
    </div>
  );
};

const styles = {
  boringButton: {
    color: darkGrey,
    backgroundColor: "rgba(0,0,0,0)",
    fontFamily: "Poppins",
    fontWeight: "200",
    borderRadius: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    opacity: "50%",
    fontSize: "16px",
    border: "none",
    boxShadow: "none",
  },
  loginButton: {
    backgroundColor: "rgba(0,0,0,0)",
    color: almostBlack,
    fontWeight: "200",
    fontSize: "16px",
    border: "none" 
  },
  interestingButton: { 
    backgroundColor: almostBlack,
    fontFamily: "Poppins",
    fontWeight: "200",
    borderRadius: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "16px",
    color: "white",
    border: "none"
  },
}