import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
const Upgrade = lazy(() => import("./Upgrade"));
const DashboardOverview  = lazy(() => import("./dashboard/DashboardOverview"));
const Transactions = lazy(() => import("./Transactions"));
const Settings = lazy(() => import("./Settings"));
const Profile = lazy(() => import("./Profile"));
const BootstrapTables = lazy(() => import("./tables/BootstrapTables"));
const Signin = lazy(() => import("./examples/Signin"));
const Signup = lazy(() => import("./examples/Signup"));
const ForgotPassword = lazy(() => import("./examples/ForgotPassword"));
const ResetPassword = lazy(() => import("./examples/ResetPassword"));
const Lock = lazy(() => import("./examples/Lock"));
const NotFoundPage = lazy(() => import("./examples/NotFound"));
const ServerError = lazy(() => import("./examples/ServerError"));
const VideoCV = lazy(() => import('./VideoCV'));
const CVViewer = lazy(() => import('./CVViewer'));
const Tracking = lazy(() => import('./Tracking'));
const Overview = lazy(() => import('./Overview'));
const Account = lazy(() => import('./Account'));
const CompetitiveJobs = lazy(() => import('./CompetitiveJobs'));
const RemoteJob = lazy(() => import('./RemoteJob'));
const YoungProfessionals = lazy(() => import('./YoungProfessionals'));
const Privacy = lazy(() => import('./Privacy'));
const PaymentComplete = lazy(() => import ('./PaymentComplete'));
const Payment = lazy(() => import ('./Payment'));

// documentation pages
const DocsOverview = lazy(() => import("./documentation/DocsOverview"));
const DocsDownload = lazy(() => import("./documentation/DocsDownload"));
const DocsQuickStart = lazy(() => import("./documentation/DocsQuickStart"));
const DocsLicense = lazy(() => import("./documentation/DocsLicense"));
const DocsFolderStructure = lazy(() => import("./documentation/DocsFolderStructure"));
const DocsBuild = lazy(() => import("./documentation/DocsBuild"));
const DocsChangelog = lazy(() => import("./documentation/DocsChangelog"));

// components

const Sidebar = lazy(() => import("../components/Sidebar"));
const Navbar = lazy(() => import("../components/Navbar"));
const Footer = lazy(() => import("../components/Footer"));
const Preloader = lazy(() => import("../components/Preloader"));

const Accordion = lazy(() => import("./components/Accordion"));
const Alerts = lazy(() => import("./components/Alerts"));
const Badges = lazy(() => import("./components/Badges"));
const Breadcrumbs = lazy(() => import("./components/Breadcrumbs"));
const Buttons = lazy(() => import("./components/Buttons"));
const Forms = lazy(() => import("./components/Forms"));
const Modals = lazy(() => import("./components/Modals"));
const Navs = lazy(() => import("./components/Navs"));
const Navbars = lazy(() => import("./components/Navbars"));
const Pagination = lazy(() => import("./components/Pagination"));
const Popovers = lazy(() => import("./components/Popovers"));
const Progress = lazy(() => import("./components/Progress"));
const Tables = lazy(() => import("./components/Tables"));
const Tabs = lazy(() => import("./components/Tabs"));
const Tooltips = lazy(() => import("./components/Tooltips"));
const Toasts = lazy(() => import("./components/Toasts"));

const renderLoader = () => <p>Loading</p>;


const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Suspense fallback={renderLoader()}>
          <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />

    </Suspense>
  );
};

const RouteWithoutLoader = ({ component: Component, ...rest }) => {
  
  return (
    <Suspense fallback={renderLoader()}>
      <Route {...rest} render={props => ( <Component {...props} /> ) } />
    </Suspense>
    
  );
};




const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Suspense fallback={renderLoader()}>
       <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
    </Suspense>
   
  );
};

const RouteWithSidebarWithoutFooterWithoutNavbar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Suspense fallback={renderLoader()}>
      <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content" style={{ minHeight: "100vh", backgroundColor: "white"}}>
          
          <Component {...props} />
         
        </main>
      </>
    )}
    />
    </Suspense>
    
  );
};



export default () => (
  <Switch>
    
    {/* ceevie pages */}
    

    {/*  */}
    <RouteWithoutLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithoutLoader exact path={Routes.CompetitiveJobs.path} component={CompetitiveJobs} />
    <RouteWithoutLoader exact path={Routes.RemoteJob.path} component={RemoteJob} />
    <RouteWithoutLoader exact path={Routes.YoungProfessionals.path} component={YoungProfessionals} />
    <RouteWithoutLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithoutLoader exact path={Routes.Signup.path} component={Signup} />
        {/* <RouteWithoutLoader exact path={Routes.Signin.path} component={Presentation} />
    <RouteWithoutLoader exact path={Routes.Signup.path} component={Presentation} /> */}
    <RouteWithoutLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithoutLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    <RouteWithoutLoader exact path={Routes.CVViewer.path} component={CVViewer} />
    <RouteWithoutLoader exact path={Routes.Privacy.path} component={Privacy} />
    <RouteWithoutLoader exact path={Routes.Payment.path} component={Payment} />
    <RouteWithoutLoader exact path={Routes.PaymentComplete.path} component={PaymentComplete} />
    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebarWithoutFooterWithoutNavbar exact path={Routes.Account.path} component={Account} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebarWithoutFooterWithoutNavbar exact path={Routes.Profile.path} component={Profile} />
    <RouteWithSidebarWithoutFooterWithoutNavbar exact path={Routes.VideoCV.path} component={VideoCV} />
    <RouteWithSidebarWithoutFooterWithoutNavbar exact path={Routes.Tracking.path} component={Tracking} />
    <RouteWithSidebarWithoutFooterWithoutNavbar exact path={Routes.Overview.path} component={Overview} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} />

    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
